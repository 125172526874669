<template>
  <div class="home-page">
    <section id="banner" class="banner">
      <Billboard />
      <LiveBanner></LiveBanner>
      <div class="banner-wrapper" @click="$router.push(routeLive)">
        <div class="container container-banner">
          <img
            class="img-fluid w-full hero-thumbnail"
            :src="
              !$store.state.home.device.isDesktop
                ? `${
                    home_banner.items.thumbnail &&
                    home_banner.items.thumbnail.large
                      ? `https://images.narasi.tv/preset:sharp/resize:fill:500:280:1/gravity:ce/plain/${home_banner.items.thumbnail.large}@webp`
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  }`
                : `${
                    home_banner.items.thumbnail &&
                    home_banner.items.thumbnail.portrait
                      ? `https://images.narasi.tv/preset:sharp/resize:fill:1000:246:1/gravity:ce/plain/${home_banner.items.thumbnail.portrait}@webp`
                      : 'https://stg.narasi.co.id/storage/images/dummy-banner.png'
                  }`
            "
            alt=""
          />

          <div class="banner-shadow"></div>
          <div class="banner-copy">
            <div class="container">
              <p
                class="channel-name"
                v-if="
                  !home_banner.loading && home_banner.items.isText !== 'live'
                "
              >
                {{ home_banner.items.channel.title }}
              </p>
              <div
                v-if="
                  !home_banner.loading && home_banner.items.isText === 'live'
                "
                class="live-icon-wrapper"
              >
                <span class="live-icon"></span>
                <p class="live-text">LIVE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-detail">
        <div class="container">
          <Link :to="routeLive" class="channel-title"
            >{{ home_banner.items.title }}
          </Link>
          <p class="streaming-time">
            {{ setDate(home_banner.items.publishDate) }}
          </p>
          <div class="list-card-video-channel">
            <div v-swiper:mySwiperVideoChannel="swiperOptions.videoChannel">
              <div class="swiper-wrapper">
                <CardOne
                  v-for="(item, index) in pinned.items"
                  :key="`pinned-${index}`"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'pinned',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            autoplay: true,
                            ref: 'pinned',
                          },
                        }
                  "
                  :image="
                    item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="item.timeVideo ? item.timeVideo : '00:00'"
                  :date="item.publishDate"
                  :channel="item.channel.title"
                  :title="item.title"
                  :isText="item.isText"
                  :isAdvertorial="item.isAdvertorial"
                  class="swiper-slide"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-video-channel"
              v-show="pinned && pinned.items.length > 4"
            >
              <Icons name="arrow-left" color="#f1f1f1" class="arrow prev" />
            </div>
            <div
              class="shadow-arrow shadow-right next-video-channel"
              v-show="pinned && pinned.items.length > 4"
            >
              <Icons name="arrow-right" color="#f1f1f1" class="arrow next" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="home-page-content">
      <!-- UNTUKMU START -->
      <section id="untukmu">
        <div
          class="container"
          v-if="
            $store.state.auth.loggedIn &&
            $store.state.auth.user.interests.length > 0 &&
            untukmu.items &&
            untukmu.items.length > 0
          "
        >
          <div class="title-wrapper">
            <TitleSection title="UNTUKMU" />
            <Link
              v-if="untukmu.items && untukmu.items.length > 6"
              :to="{
                name: 'interest-untukmu',
              }"
              class="lihat-semua"
              >Lihat Semua
            </Link>
          </div>
          <div class="list-card-untukmu">
            <div
              v-swiper:mySwiperUntukmu="swiperOptions.untukmu"
              @transition-start="onSwiperSlideChange('untukmu')"
            >
              <div class="swiper-wrapper">
                <ShimmerCardHome
                  v-show="untukmu.loading"
                  v-for="(item, index) in 4"
                  :key="`untukmu-shimmer-${index}`"
                  class="swiper-slide"
                />
                <CardTwo
                  v-show="!untukmu.loading"
                  v-for="(item, index) in untukmu.items"
                  :key="`untukmu-${index}`"
                  :image="
                    item.thumbnail && item.thumbnail.medium
                      ? item.thumbnail.medium
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="item.timeVideo ? item.timeVideo : '00:00'"
                  :channel="item.channel.title"
                  :channelSlug="item.channel.slug"
                  :programSlug="
                    item.channel.program ? item.channel.program.slug : ''
                  "
                  :category="item.category.title"
                  :categorySlug="item.category.slug"
                  :title="item.title"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'untukmu',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            autoplay: true,
                            ref: 'untukmu',
                          },
                        }
                  "
                  :isText="item.isText"
                  :isAdvertorial="item.isAdvertorial"
                  class="swiper-slide"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left"
              :class="arrow.untukmu.prev ? 'hide' : ''"
            ></div>
            <div
              class="shadow-arrow shadow-right"
              :class="arrow.untukmu.next ? 'hide' : ''"
            ></div>
            <Icons
              name="arrow-left"
              class="arrow prev prev-untukmu"
              :class="arrow.untukmu.prev ? 'hide' : ''"
            />
            <Icons
              name="arrow-right"
              class="arrow next next-untukmu"
              :class="arrow.untukmu.next ? 'hide' : ''"
            />
          </div>
        </div>
      </section>
      <!-- UNTUKMU END -->

      <!-- START ADS -->
      <!-- <AdsHorizontalFixedFreakout
        id="placement_before_cerita_narasi"
        class="is-mobile"
      ></AdsHorizontalFixedFreakout> -->
      <HomeCeritaNarasi />
      <!-- ADS END -->

      <!-- START ARTIKEL NARASI -->
      <section class="articles-terkini">
        <div class="container">
          <div v-if="articles_highlight.loading" class="title-wrapper mt-4">
            <Shimmer width="200px" height="30px" />
            <Shimmer width="200px" height="30px" />
          </div>
          <div v-else class="title-wrapper">
            <TitleSection title="ARTIKEL NARASI" />
            <Link :to="'/indeks'" class="lihat-semua">Lihat Semua</Link>
          </div>
          <div class="row">
            <div class="col-md-4">
              <Shimmer
                v-if="articles_highlight.loading"
                width="100%"
                height="365px"
                class="img-presisi"
              />
              <div v-else class="card-wrapper">
                <Link
                  :to="`/read/${articles_highlight.items.channel.slug}/${articles_highlight.items.slug}`"
                >
                  <ImageResponsive
                    class="img-presisi"
                    :imageUrl="articles_highlight.items.thumbnail.medium"
                    :fromUrl="true"
                    width="350"
                    height="350"
                  />
                </Link>
                <div class="card-caption">
                  <Link
                    class="category"
                    :to="`/program/${articles_highlight.items.channel.program.slug}/${articles_highlight.items.channel.slug}?type=highlights`"
                    >{{ articles_highlight.items.channel.title }}</Link
                  >
                  <Link
                    :to="`/read/${articles_highlight.items.channel.slug}/${articles_highlight.items.slug}`"
                    class="title"
                    >{{ articles_highlight.items.title }}</Link
                  >
                </div>
              </div>
            </div>
            <div class="col-md-8 list-articles-small">
              <div class="row">
                <template v-if="articles.loading">
                  <div
                    class="col-md-6"
                    v-for="(item, index) in 6"
                    :key="`shimmer-terkini-${index}`"
                  >
                    <div class="item-wrapper">
                      <Shimmer width="130px" height="100px" />
                      <span class="right-wrapper w-100">
                        <Shimmer
                          class="category my-2"
                          width="20%"
                          height="10px"
                        />
                        <Shimmer class="category" width="100%" height="30px" />
                      </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="col-md-6"
                    v-for="(article, indeks) in articles.items.slice(1, 7)"
                    :key="`terkini-${indeks}`"
                  >
                    <div class="item-wrapper">
                      <Link
                        :to="`/read/${article.channel.slug}/${article.slug}`"
                      >
                        <ImageResponsive
                          class="img-small"
                          :imageUrl="article.thumbnail.medium"
                          :fromUrl="true"
                          width="100"
                          height="100"
                          widthMobile="100"
                          heightMobile="100"
                        />
                      </Link>
                      <div class="right-wrapper">
                        <Link
                          class="category"
                          :to="`/program/${article.channel.program.slug}/${article.channel.slug}?type=highlights`"
                          >{{ article.channel.title }}</Link
                        >
                        <Link
                          class="title"
                          :to="`/read/${article.channel.slug}/${article.slug}`"
                          >{{ article.title }}</Link
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END ARTIKEL NARASI -->

      <!-- START TERPOPULER -->
      <section class="populars">
        <div class="container">
          <div
            v-if="terpopulerVideo.loading"
            class="card-populer-shimmer border-radius-5 p-2 mt-3"
            width="100%"
            height="527px"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="d-flex justify-content-between align-items-center">
                  <Shimmer width="40%" class="border-radius-5" height="30px" />
                  <Shimmer width="20%" class="border-radius-5" height="30px" />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <Shimmer
                      width="100%"
                      class="border-radius-5 my-3"
                      height="184px"
                    />
                  </div>
                  <div class="col-md-6">
                    <div
                      class="border-radius-5 w-100 my-3 d-none d-md-block"
                      height="40px"
                    >
                      <Shimmer
                        width="100%"
                        class="border-radius-5"
                        height="40px"
                      />
                      <Shimmer
                        width="100%"
                        class="border-radius-5 my-1"
                        height="80px"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-6"
                    v-for="(item, index) in 6"
                    :key="`shimmer-populer-${index}`"
                  >
                    <Shimmer
                      width="100%"
                      class="border-radius-5 my-2"
                      height="30px"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between align-items-center">
                  <Shimmer width="40%" class="border-radius-5" height="30px" />
                  <Shimmer width="20%" class="border-radius-5" height="30px" />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <Shimmer
                      width="100%"
                      class="border-radius-5 my-3"
                      height="184px"
                    />
                  </div>
                  <div class="col-md-6">
                    <div
                      class="border-radius-5 w-100 my-3 d-none d-md-block"
                      height="40px"
                    >
                      <Shimmer
                        width="100%"
                        class="border-radius-5"
                        height="40px"
                      />
                      <Shimmer
                        width="100%"
                        class="border-radius-5 my-1"
                        height="80px"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-6"
                    v-for="(item, index) in 6"
                    :key="`shimmer-populer-${index}`"
                  >
                    <Shimmer
                      width="100%"
                      class="border-radius-5 my-2"
                      height="30px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="main-populars">
            <div class="row">
              <div class="col-md-6">
                <div class="title-wrapper">
                  <TitleSection title="VIDEO TERPOPULER" />
                  <Link :to="'/video/terpopuler'" class="lihat-semua"
                    >Lihat Semua
                  </Link>
                </div>

                <div class="main-content-populars">
                  <div
                    class="populer-header"
                    v-if="terpopulerVideo.items.length > 0"
                  >
                    <div class="item-large">
                      <Link
                        :to="`/video/${terpopulerVideo.items[0].channel.slug}/${terpopulerVideo.items[0].slug}?ref=terpopuler`"
                      >
                        <div class="poster">
                          <ImageResponsive
                            class="img-populars"
                            :imageUrl="
                              terpopulerVideo.items[0].thumbnail.medium
                            "
                            :fromUrl="true"
                          />
                          <span class="duration">{{
                            terpopulerVideo.items[0].timeVideo
                          }}</span>
                        </div>
                      </Link>
                      <div class="populars-caption">
                        <Link
                          :to="`/video/${terpopulerVideo.items[0].channel.slug}/${terpopulerVideo.items[0].slug}?ref=terpopuler`"
                        >
                          <h3 class="title">
                            {{ terpopulerVideo.items[0].title }}
                          </h3>
                          <span class="description">{{
                            terpopulerVideo.items[0].short
                          }}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="populer-section">
                    <div
                      class="populer-item-list video-populer"
                      v-for="(article, index) in terpopulerVideo.items.filter(
                        (item, index) => index != 0
                      )"
                      :key="`populars-video-${index}`"
                    >
                      <div class="item-small">
                        <Link
                          :to="`/video/${article.channel.slug}/${article.slug}?ref=terpopuler`"
                        >
                          <span class="title"
                            ><i class="material-icons">play_circle</i>
                            {{ article.title }}</span
                          >
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title-wrapper">
                  <TitleSection title="ARTIKEL TERPOPULER" />
                  <Link :to="'/read/terpopuler'" class="lihat-semua"
                    >Lihat Semua
                  </Link>
                </div>

                <div class="main-content-populars">
                  <div
                    class="populer-header"
                    v-if="terpopulerText.items.length > 0"
                  >
                    <div class="item-large">
                      <Link
                        :to="`/read/${terpopulerText.items[0].channel.slug}/${terpopulerText.items[0].slug}?ref=terpopuler`"
                      >
                        <div class="poster">
                          <ImageResponsive
                            class="img-populars"
                            :imageUrl="terpopulerText.items[0].thumbnail.medium"
                            :fromUrl="true"
                            width="248"
                            height="150"
                          />
                        </div>
                      </Link>
                      <div class="populars-caption">
                        <Link
                          :to="`/read/${terpopulerText.items[0].channel.slug}/${terpopulerText.items[0].slug}?ref=terpopuler`"
                        >
                          <h3 class="title">
                            {{ terpopulerText.items[0].title }}
                          </h3>
                          <span class="description">{{
                            terpopulerText.items[0].short
                          }}</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div class="populer-section">
                    <div
                      class="populer-item-list article-populer"
                      v-for="(article, index) in terpopulerText.items.filter(
                        (item, index) => index != 0
                      )"
                      :key="`populars-text-${index}`"
                    >
                      <div class="item-small">
                        <Link
                          :to="`/read/${article.channel.slug}/${article.slug}?ref=terpopuler`"
                        >
                          <span class="title">{{ article.title }}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- END TERPOPULER -->

      <!-- ADS PROPS START -->
      <AdsHorizontalFixedProps
        width="315px"
        height="290px"
        id="props_placement_before_populer"
      ></AdsHorizontalFixedProps>
      <!-- ADS PROPS END -->

      <!-- PILIHAN NARASI START -->
      <section
        id="pilihan-narasi"
        v-if="pilihan_narasi && pilihan_narasi.items.length > 0"
      >
        <div class="container">
          <div class="title-wrapper">
            <TitleSection title="PILIHAN NARASI" />
            <Link
              v-if="pilihan_narasi.items && pilihan_narasi.items.length > 6"
              :to="{
                name: 'pilihan-narasi',
              }"
              class="lihat-semua"
              >Lihat Semua
            </Link>
          </div>
          <div class="list-card-pilihan-narasi">
            <div v-swiper:mySwiperPilihanNarasi="swiperOptions.pilihanNarasi">
              <div class="swiper-wrapper">
                <ShimmerCardHome
                  v-show="pilihan_narasi.loading"
                  v-for="(item, index) in 4"
                  :key="`pilihan-narasi-shimmer-${index}`"
                  class="swiper-slide"
                />
                <CardThree
                  v-show="!pilihan_narasi.loading"
                  v-for="(item, index) in pilihan_narasi.items"
                  :key="`pilihan-narasi-${index}`"
                  :image="
                    item.thumbnail && item.thumbnail.large
                      ? item.thumbnail.large
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :totalVideo="item.itemCount"
                  :title="item.title"
                  :to="{
                    name: 'playlist-category-channel',
                    params: {
                      category: item.slug,
                      channel: item.channel.slug,
                    },
                    query: {
                      ref: 'pilihan-narasi',
                    },
                  }"
                  :toDetail="{
                    name: 'playlist-category-channel-slug',
                    params: {
                      category: item.slug,
                      channel:
                        item.channel && item.channel.slug
                          ? item.channel.slug
                          : '',
                      slug: item.firstItem,
                    },
                    query: {
                      autoplay: true,
                      ref: 'pilihan-narasi',
                    },
                  }"
                  class="swiper-slide"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-pilihan-narasi"
              v-show="pilihan_narasi && pilihan_narasi.items.length > 4"
            >
              <Icons name="arrow-left" class="arrow prev" />
            </div>
            <div
              class="shadow-arrow shadow-right next-pilihan-narasi"
              v-show="pilihan_narasi && pilihan_narasi.items.length > 4"
            >
              <Icons name="arrow-right" class="arrow next" />
            </div>
          </div>
        </div>

        <!-- START ADS  -->
        <!-- Homepage Horizontal Ads 1 || Home Before Boosted Content  -->
        <HomeBeforeBoosted />
        <!-- END ADS -->
      </section>
      <!-- PILIHAN NARASI END -->
      <!-- BOOSTED CONTENT START -->
      <section id="boosted-content">
        <div class="container">
          <div class="list-card-boosted-content">
            <div v-swiper:mySwiperBoostedContent="swiperOptions.boostedContent">
              <div class="swiper-wrapper">
                <ShimmerCardHome
                  v-show="boosted_content.loading"
                  v-for="(item, index) in 4"
                  :key="`boosted-content-shimmer${index}`"
                  class="swiper-slide"
                />
                <CardFour
                  v-show="!boosted_content.loading"
                  v-for="(item, index) in boosted_content.items"
                  :key="`boosted-content-${index}`"
                  :imageLogo="
                    item.channel.program && item.channel.program.logo
                      ? item.channel.program.logo
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageLogoFromUrl="true"
                  :image="
                    item.thumbnail && item.thumbnail.large
                      ? item.thumbnail.large
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="item.timeVideo ? item.timeVideo : '00:00'"
                  :isText="item.isText"
                  :isAdvertorial="item.isAdvertorial"
                  :category="item.category ? item.category.title : ''"
                  :categorySlug="item.category ? item.category.slug : ''"
                  :channel="item.channel ? item.channel.title : ''"
                  :channelSlug="item.channel.slug"
                  :programSlug="
                    item.channel.program ? item.channel.program.slug : ''
                  "
                  :title="item.title"
                  class="swiper-slide"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'boosted-content',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            autoplay: true,
                            ref: 'boosted-content',
                          },
                        }
                  "
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-boosted-content"
              v-show="boosted_content && boosted_content.items.length > 4"
            >
              <Icons name="arrow-left" class="arrow prev" />
            </div>
            <div
              class="shadow-arrow shadow-right next-boosted-content"
              v-show="boosted_content && boosted_content.items.length > 4"
            >
              <Icons name="arrow-right" class="arrow next" />
            </div>
          </div>
        </div>
      </section>
      <!-- BOOSTED CONTENT END -->
      <!-- NARASI ACADEMY START -->
      <section id="narasi-academy">
        <div class="container">
          <div class="title-wrapper">
            <TitleSection title="NARASI ACADEMY" />
            <!-- <Link
              :to="{
                name: 'academy',
              }"
              class="lihat-semua"
              >Lihat Semua</Link
            > -->
          </div>
          <div class="list-card-narasi-academy">
            <div v-swiper:mySwiperIkutiKelas="swiperOptions.ikuti_kelas">
              <div class="swiper-wrapper">
                <ShimmerListCard v-if="ikutiKelas.loading" />
                <CardTwentyThree
                  class="swiper-slide"
                  v-for="(card, index) in ikutiKelas.items"
                  v-show="ikutiKelas.items.length > 0"
                  :key="'card-ikuti-kelas' + index"
                  :image="
                    card.thumbnail && card.thumbnail.large
                      ? card.thumbnail.large
                      : 'https://stg.narasi.co.id/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :categories="card.category"
                  :classType="card.classType"
                  :title="card.title"
                  :slug="card.slug"
                  :description="card.description"
                  :datetime="card.startDate"
                  :isSoon="card.isSoon"
                  :price="card.price"
                  :discountPrice="card.discountPrice"
                  :discount="card.discount"
                  :to="{
                    name: 'academy-detail-slug',
                    params: {
                      slug: card.slug,
                    },
                  }"
                  :startEvent="card.startDate"
                  :endEvent="card.endDate"
                  :startRegis="card.startRegistrationDate"
                  :endRegis="card.endRegistrationDate"
                />
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div
              class="shadow-arrow shadow-left prev-ikuti_kelas"
              v-show="ikutiKelas && ikutiKelas.items.length > 4"
            >
              <Icons name="arrow-left" class="arrow prev" />
            </div>
            <div
              class="shadow-arrow shadow-right next-ikuti_kelas"
              v-show="ikutiKelas && ikutiKelas.items.length > 4"
            >
              <Icons name="arrow-right" class="arrow next" />
            </div>
          </div>
        </div>
      </section>
      <!-- NARASI ACADEMY END -->

      <!-- BOTTOM ADS START -->
      <!-- Homepage Horizontal Ads 2 -->
      <AdsHorizontalGam
        id="placement_after_academy"
        unit="div-gpt-ad-1678440436518-0"
        slotAds="/22849909437/homepage-horizontal-2"
      ></AdsHorizontalGam>
      <!-- BOTTOM ADS END -->
    </div>
  </div>
</template>

<script>
import config from "../config";
import { mapGetters, mapState } from "vuex";
import helper from "/assets/js/helpers.js";
import CardEleven from "/components/cards/CardEleven.vue";
import CardLiveStreaming from "/components/cards/CardLiveStreaming.vue";
import CardOne from "/components/cards/CardOne.vue";
import CardTwo from "/components/cards/CardTwo.vue";
import CardThree from "/components/cards/CardThree.vue";
import CardFour from "/components/cards/CardFour.vue";
import CardTwentyThree from "/components/cards/CardTwentyThree.vue";
import CardTwentyFour from "/components/cards/CardTwentyFour.vue";
import helpers from "../assets/js/helpers";
import HomeCeritaNarasi from "/components/ads/homepage/HomeCeritaNarasi.vue";
import HomeBeforeBoosted from "/components/ads/homepage/HomeBeforeBoosted.vue";
import Billboard from "/components/ads/homepage/Billboard.vue";
import LiveBanner from "/components/live/Banner";
import Shimmer from "/components/Shimmer";
import ImageResponsiveBanner from "/components/ImageResponsiveBanner";
import Link from "/components/Link";
import ImageResponsive from "/components/ImageResponsive";
import Icons from "@/components/Icons";
import ShimmerCardHome from "@/components/shimmer/CardHome.vue";
import AdsHorizontalFixedProps from "@/components/ads/HorizontalFixedProps.vue";
import AdsHorizontalGam from "@/components/ads/HorizontalGam";
import ShimmerListCard from "@/components/shimmer/ListCard";
import TitleSection from "@/components/TitleSection";
import QuickCountWidget from "../components/QuickCountWidget.vue";
import PesantrenKilat from "@/components/ramadan/PesantrenKilat.vue";
import JadwalImsak from "@/components/ramadan/JadwalImsak.vue";

export default {
  async asyncData({ app, error, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Ruang bersama untuk bertukar ide dan gagasan",
        desc: "Ruang untuk berdiskusi para anak muda serta kumpulan berita terkini dan terbaru.",
        image: "https://narasi.tv/icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerListCard,
    AdsHorizontalGam,
    TitleSection,
    AdsHorizontalFixedProps,
    ShimmerCardHome,
    Icons,
    ImageResponsive,
    Link,
    ImageResponsiveBanner,
    Shimmer,
    LiveBanner,
    CardEleven,
    CardOne,
    CardTwo,
    CardThree,
    CardFour,
    CardLiveStreaming,
    CardTwentyThree,
    CardTwentyFour,
    HomeCeritaNarasi,
    HomeBeforeBoosted,
    Billboard,
    PesantrenKilat,
    JadwalImsak,
    QuickCountWidget,
  },
  data() {
    return {
      isStories: false,
      screenWidth: null,
      centerAds: true,
      arrow: {
        liveStreaming: {
          prev: true,
          next: false,
        },
        videoChannel: {
          prev: true,
          next: false,
        },
        untukmu: {
          prev: true,
          next: false,
        },
        pilihanNarasi: {
          prev: true,
          next: false,
        },
        boostedContent: {
          prev: true,
          next: false,
        },
        ikuti_kelas: {
          prev: true,
          next: false,
        },
      },
      swiperOptions: {
        liveStreaming: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false,
          },
          observeParents: true,
          navigation: {
            prevEl: ".prev-video-channel",
            nextEl: ".next-video-channel",
          },
          breakpoints: {
            1023: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 2.2,
              spaceBetween: 8,
            },
          },
        },
        videoChannel: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false,
          },
          navigation: {
            prevEl: ".prev-video-channel",
            nextEl: ".next-video-channel",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 2.2,
              spaceBetween: 8,
            },
          },
        },
        untukmu: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          navigation: {
            prevEl: ".prev-untukmu",
            nextEl: ".next-untukmu",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        pilihanNarasi: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          navigation: {
            prevEl: ".prev-pilihan-narasi",
            nextEl: ".next-pilihan-narasi",
          },
          breakpoints: {
            820: {
              slidesPerView: 2.2,
              spaceBetween: 16,
            },
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        boostedContent: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          navigation: {
            prevEl: ".prev-boosted-content",
            nextEl: ".next-boosted-content",
          },
          breakpoints: {
            820: {
              slidesPerView: 2.2,
              spaceBetween: 16,
            },
            1023: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        ikuti_kelas: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-ikuti_kelas",
            nextEl: ".next-ikuti_kelas",
          },
          breakpoints: {
            820: {
              slidesPerView: 2.2,
              spaceBetween: 16,
            },
            1023: {
              slidesPerView: 3.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          },
        },
      },
    };
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      home_banner: (state) => {
        return state.home.home_banner;
      },
      pinned: (state) => {
        return state.home.pinned;
      },
      untukmu: (state) => {
        return state.home.untukmu;
      },
      terpopulerVideo: (state) => {
        return state.home.terpopulerVideo;
      },
      terpopulerText: (state) => {
        return state.home.terpopulerText;
      },
      articles_highlight: (state) => {
        return state.home.articles_highlight;
      },
      articles: (state) => {
        return state.home.articles;
      },
      pilihan_narasi: (state) => {
        return state.home.pilihan_narasi;
      },
      boosted_content: (state) => {
        return state.home.boosted_content;
      },
      ikutiKelas: (state) => {
        return state.academies.academyClass;
      },
    }),
    routeLive() {
      if (this.home_banner.items.isText === "live") {
        return {
          name: "live-slug",
          params: {
            slug: this.home_banner.items.slug,
          },
        };
      } else if (this.home_banner.items.isText) {
        return {
          name: `read-channel-slug`,
          params: {
            channel: this.home_banner.items.channel.slug,
            slug: this.home_banner.items.slug,
          },
          query: {
            ref: "home-banner",
          },
        };
      } else {
        return {
          name: `video-channel-slug`,
          params: {
            channel: this.home_banner.items.channel.slug,
            slug: this.home_banner.items.slug,
          },
          query: {
            autoplay: true,
            ref: "home-banner",
          },
        };
      }
    },
  },
  async fetch() {
    await this.$store.dispatch("home/getHomeBanner");
    await this.$store.dispatch("home/getPinned");
  },
  methods: {
    async initData() {
      if (this.$store.state.auth.loggedIn) {
        await this.$store.dispatch("home/getUntukmu");
      }
      await this.$store.dispatch("home/getArticlesHighlight");
      await this.$store.dispatch("home/getArticles");
      await this.$store.dispatch("home/getTerpopulerVideo");
      await this.$store.dispatch("home/getTerpopulerText");
      await this.$store.dispatch("home/getPilihanNarasi");
      await this.$store.dispatch("home/getBoostedContent");
      await this.$store.dispatch("academies/getClass");
    },
    setDate(param) {
      return helper.formatDate(param);
    },
    onSwiperSlideChange(params) {
      // this.totalSlide = swiper.slides.length;
      switch (params) {
        case "live-streaming":
          this.arrow.liveStreaming.next = this.mySwiperLiveStreaming.isEnd;
          this.arrow.liveStreaming.prev =
            this.mySwiperLiveStreaming.isBeginning;
          break;
        case "video-channel":
          this.arrow.videoChannel.next = this.mySwiperVideoChannel.isEnd;
          this.arrow.videoChannel.prev = this.mySwiperVideoChannel.isBeginning;
          break;
        case "untukmu":
          this.arrow.untukmu.next = this.mySwiperUntukmu.isEnd;
          this.arrow.untukmu.prev = this.mySwiperUntukmu.isBeginning;
          break;
        case "terpopuler":
          this.arrow.terpopuler.next = this.mySwiperTerpopuler.isEnd;
          this.arrow.terpopuler.prev = this.mySwiperTerpopuler.isBeginning;
          break;
        case "artikel-narasi":
          this.arrow.artikelNarasi.next = this.mySwiperArtikelNarasi.isEnd;
          this.arrow.artikelNarasi.prev =
            this.mySwiperArtikelNarasi.isBeginning;
          break;
        case "pilihan-narasi":
          this.arrow.pilihanNarasi.next = this.mySwiperPilihanNarasi.isEnd;
          this.arrow.pilihanNarasi.prev =
            this.mySwiperPilihanNarasi.isBeginning;
          break;
        case "boosted-content":
          this.arrow.boostedContent.next = this.mySwiperBoostedContent.isEnd;
          this.arrow.boostedContent.prev =
            this.mySwiperBoostedContent.isBeginning;
          break;
        case "ikuti-kelas":
          this.arrow.ikuti_kelas.next = this.mySwiperIkutiKelas.isEnd;
          this.arrow.ikuti_kelas.prev = this.mySwiperIkutiKelas.isBeginning;
          break;
      }
    },
  },
  async mounted() {
    if (this.$route.query.code) {
      try {
        await this.$recaptcha.init();
      } catch (e) {
        console.error(e);
      }

      const token = await this.$recaptcha.execute("activate");

      this.$axios
        .$post(
          `${config.USER_SERVICE_API}/api/auth/activate/${this.$route.query.code}`,
          {
            headers: {
              recaptcha: token,
            },
          }
        )
        .then((response) => {
          this.$toast.success(response.message);
          if (this.$route.query.redirect) {
            window.location = helpers.isUrlValid(redirect)
              ? redirect
              : "https://narasi.tv";
          } else {
            window.location.href = this.$router.resolve(
              "/auth/with-email?verification=true"
            ).href;
          }
        })
        .catch((error) => {
          this.$toast.error(
            `Aktivasi akun gagal, ${error.response.data.message}`
          );
        });
    }
    this.initData();
    this.screenWidth = window.innerWidth;
  },
};
</script>

<style lang="scss" scoped>
.quick-count-wrapper {
  padding-top: 16px;
  // .container {
  //   @media only screen and (max-width: 1024px) {
  //     padding-right: 0;
  //     padding-left: 0;
  //   }
  // }
}

.suara-penentu {
  padding-top: 16px;
}

::v-deep #placement_before_boosted_articles {
  @media only screen and (max-width: 1024px) {
    height: fit-content !important;
  }
  .adsbygoogle {
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0 !important;
    }
  }
}
::v-deep #placement_before_cerita_narasi {
  #mtb {
    .frame {
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
        height: auto !important;
      }
      a {
        img {
          @media only screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
      }
    }
  }
}
#suara-penentu {
  @media only screen and (max-width: 1024px) {
    padding: 12px 0;
  }
}
.shimmer-top-banner {
  @media only screen and (max-width: 1024px) {
    height: 235px !important;
  }
}
.swiper-wrapper {
  height: fit-content !important;
}
.home-page {
  #banner {
    background: black;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    ::v-deep .google-auto-placed {
      width: 100% !important;
      order: 10 !important;
      padding-bottom: 24px !important;
    }
    .banner-wrapper {
      position: relative;
      cursor: pointer;
      .container {
        &.container-banner {
          @media only screen and (max-width: 1024px) {
            padding-right: 0;
            padding-left: 0;
          }
          .hero-thumbnail {
            @media only screen and (min-width: 1024px) {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }
        }
        .banner-shadow {
          width: 100%;
          height: 259.77px;
          background: linear-gradient(
            180deg,
            rgba(2, 2, 2, 0) 4.32%,
            rgba(0, 0, 0, 0.9)
          );
          position: absolute;
          bottom: 0;
          left: 0;
          @media only screen and (max-width: 1024px) {
            height: 100%;
          }
        }
        .banner-copy {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          .container {
            @media only screen and (max-width: 1024px) {
              margin-bottom: 8px;
            }

            .live-icon-wrapper {
              // background: #ffe900;
              background: #e73638;
              display: flex;
              align-items: center;
              width: fit-content;
              padding: 5px 10px;
              margin-bottom: 16px;
              border-radius: 5px;

              .live-icon {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: white;
                line-height: 0;
                margin-right: 5px;
                -webkit-animation: breathing 1s ease-out infinite normal;
                animation: breathing 1s ease-out infinite normal;
                @media only screen and (max-width: 1024px) {
                  width: 7px;
                  height: 7px;
                }
                @keyframes breathing {
                  0% {
                    -webkit-transform: scale(0.8);
                    -ms-transform: scale(0.8);
                    transform: scale(0.8);
                  }

                  25% {
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                  }

                  60% {
                    -webkit-transform: scale(0.8);
                    -ms-transform: scale(0.8);
                    transform: scale(0.8);
                  }

                  100% {
                    -webkit-transform: scale(0.8);
                    -ms-transform: scale(0.8);
                    transform: scale(0.8);
                  }
                }
              }

              .live-text {
                margin: 0;
                color: #fafafa;
                font-size: 14px;
                font-weight: 700;
                @media only screen and (max-width: 1024px) {
                  font-size: 12px;
                }
              }
            }

            .shimmer-channel-name {
              width: 15% !important;
              height: 25px !important;
              @media only screen and (max-width: 1024px) {
                width: 35% !important;
                height: 25px !important;
              }
            }

            .channel-name {
              font-size: 16px;
              font-weight: 500;
              color: #ffe900;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .banner-detail {
      background: black;
      padding: 0 0 32px;
      min-height: 20vw;
      @media only screen and (max-width: 1024px) {
        min-height: 72vw;
      }
      .container {
        .shimmer-channel-title {
          width: 40% !important;
          height: 25px !important;
          @media only screen and (max-width: 1024px) {
            width: 70% !important;
          }
        }

        .channel-title {
          font-size: 32px;
          font-weight: 500;
          color: #fafafa;
          display: block;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            font-size: 24px;
            line-height: 30px;
            display: block;
          }

          &:hover {
            color: #fafafa !important;
          }
        }

        .shimmer-streaming-time {
          width: 30% !important;
          height: 25px !important;
          @media only screen and (max-width: 1024px) {
            width: 50% !important;
          }
        }

        .streaming-time {
          font-size: 16px;
          font-weight: 500;
          color: white;
          margin-bottom: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            margin-bottom: 14px;
          }
        }

        .list-card-video-channel {
          position: relative;
          overflow: hidden;
          @media only screen and (max-width: 1024px) {
            overflow: unset;
          }
          .swiper-wrapper {
            .card-one {
              margin-right: 16px;
              @media only screen and (max-width: 1024px) {
                margin-right: 8px;
              }
            }
          }
          .shimmer-card-pinned {
            height: 272.53px;
            overflow: hidden;
            @media only screen and (max-width: 1024px) {
              width: 163px;
              height: fit-content;
            }
          }
          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 0%, 0),
                rgba(0, 0, 0, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 0%, 0),
                rgba(0, 0, 0, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }
  }

  .home-page-content {
    padding: 24px 0;

    #live-streaming {
      @media only screen and (max-width: 1024px) {
        margin-top: 10px;
      }

      .container {
        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .lihat-semua {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline !important;

            &:hover {
              color: #4a25aa !important;
            }

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .list-card-streaming {
          margin-top: 20px;
          margin-bottom: 40px;
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.hide {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }

    #untukmu {
      @media only screen and (max-width: 1024px) {
        margin-top: 10px;
      }

      .container {
        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .lihat-semua {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline !important;

            &:hover {
              color: #4a25aa !important;
            }

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .list-card-untukmu {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.hide {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }

    #terpopuler {
      margin-top: 48px;
      @media only screen and (max-width: 1024px) {
        margin-top: 32px;
      }
      // WITHOUT PILIHAN NARASI
      &.last {
        margin: 48px 0;
        @media only screen and (max-width: 1024px) {
          margin: 32px 0;
        }
      }

      .container {
        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .lihat-semua {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline !important;

            &:hover {
              color: #4a25aa !important;
            }

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .list-card-terpopuler {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                #f1f1f1
              );
            }

            &.hide {
              opacity: 0;
              z-index: 0;
            }
          }

          .arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            width: auto;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            &.prev {
              left: 0;
            }

            &.next {
              right: 0;
            }

            &.hide {
              display: none;
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }

    .articles-terkini {
      padding-top: 24px;
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-right: auto;
          margin-left: auto;
        }
        .lihat-semua {
          font-size: 16px;
          font-weight: 500;
          color: #4a25aa;
          text-decoration: underline !important;
          &:hover {
            color: #4a25aa !important;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
      .card-wrapper {
        position: relative;
        .card-caption {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 16px 24px;
          height: 145px;
          background: #000000b2;
          .category {
            display: block;
            font-size: 16px;
            color: #f7e600;
            &:hover {
              color: #f4f4f4 !important;
            }
          }
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #f4f4f4;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            &:hover {
              color: #f4f4f4 !important;
            }
          }
        }
      }
      .row {
        .col-md-4 {
          @media screen and (min-device-width: 820px) and (max-device-width: 1024px) {
            width: 100%;
          }
          @media screen and (min-device-width: 768px) and (max-device-width: 820px) {
            width: 100%;
          }
        }
        .col-md-8 {
          @media screen and (min-device-width: 820px) and (max-device-width: 1024px) {
            width: 100%;
          }
          @media screen and (min-device-width: 768px) and (max-device-width: 820px) {
            width: 100%;
          }
        }
      }
      .list-articles-small {
        @media only screen and (max-width: 1024px) {
          margin-top: 30px;
        }
        .row {
          row-gap: 16px;
          .col-md-6 {
            &:nth-last-child(2) {
              .item-wrapper {
                border-bottom: unset;
                @media only screen and (max-width: 1024px) {
                  border-bottom: 1px solid #d9d9d9;
                }
              }
            }
            &:nth-last-child(1) {
              .item-wrapper {
                border-bottom: unset;
              }
            }
            .item-wrapper {
              display: flex;
              padding-bottom: 16px;
              border-bottom: 1px solid #d9d9d9;
              .right-wrapper {
                display: block;
                margin-left: 10px;
                .category {
                  font-size: 11px;
                  color: #4a25aa;
                }
                .title {
                  font-size: 14px;
                  color: #0c0b0d;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }

    .populars {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 16px;
      }
      .container {
        @media only screen and (max-width: 1024px) {
          padding-right: 0;
          padding-left: 0;
        }
      }
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          margin-right: auto;
          margin-left: auto;
        }
        .lihat-semua {
          font-size: 16px;
          font-weight: 500;
          color: #4a25aa;
          text-decoration: underline !important;
          &:hover {
            color: #4a25aa !important;
          }
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
      .main-populars {
        padding: 24px 16px;
        margin-top: 24px;
        background: #dddddd;
        .main-content-populars {
          .populer-section {
            margin-top: 24px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            column-gap: 16px;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(1, 1fr);
              margin-top: unset;
              margin-bottom: 24px;
            }
            .populer-header {
              margin-bottom: 24px;
              @media only screen and (max-width: 1024px) {
                margin-bottom: 8px;
              }
            }

            .populer-item-list {
              border-bottom: 1px solid #a4a4a4;
              display: flex;
              align-items: center;
              &:last-child {
                border-bottom: unset;
              }
              &:nth-last-child(2) {
                @media only screen and (min-width: 1024px) {
                  border-bottom: unset;
                }
              }
              .item-small {
                padding: 16px 0;
                a {
                  display: flex;
                  .title {
                    font-size: 14px;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    .material-icons {
                      font-size: 12px;
                      color: #4a25aa;
                    }
                  }
                }
              }
            }
          }
          .item-large {
            display: flex;
            @media only screen and (max-width: 1024px) {
              display: block;
            }
            .poster {
              position: relative;
              // @media screen and (min-device-width: 820px) and (max-device-width: 1024px) {
              //   .image {
              //     max-width: 200px;
              //     height: 150px;
              //   }
              // }
              .duration {
                position: absolute;
                bottom: 0;
                right: 0;
                background: #000000b2;
                color: #ffffff;
                font-size: 11px;
                font-weight: 500;
                padding: 5px 10px;
                border-top-left-radius: 5px;
              }
            }
            .populars-caption {
              display: block;
              margin-left: 16px;
              @media only screen and (max-width: 1024px) {
                margin-left: unset;
                margin-top: 16px;
              }
              .title {
                font-size: 18px;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
              .description {
                color: #717171;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                @media only screen and (max-width: 1024px) {
                  display: none;
                }
              }
            }
            img {
              width: 248px;
              height: 150px;
              object-fit: cover;
            }
          }
        }
      }
    }

    #pilihan-narasi {
      margin: 48px 0;
      @media only screen and (max-width: 1024px) {
        margin: 32px 0;
      }

      .container {
        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .lihat-semua {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline !important;

            &:hover {
              color: #4a25aa !important;
            }

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .list-card-pilihan-narasi {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }

    #boosted-content {
      background: white;
      padding: 48px 0;
      @media only screen and (max-width: 1024px) {
        padding: 32px 0;
      }

      .container {
        .list-card-boosted-content {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(255, 255, 255, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(255, 255, 255, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }

    #narasi-academy {
      margin: 48px 0;
      @media only screen and (max-width: 1024px) {
        margin: 32px 0;
      }

      .container {
        .title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          .lihat-semua {
            font-size: 16px;
            font-weight: 500;
            color: #4a25aa;
            text-decoration: underline !important;

            &:hover {
              color: #4a25aa !important;
            }

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .list-card-narasi-academy {
          position: relative;

          .shadow-arrow {
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            padding: 0;
            background: rgb(0, 0, 0);
            z-index: 2;
            opacity: 1;
            transition: ease-in-out 0.5s;
            @media only screen and (max-width: 1024px) {
              display: none;
            }

            svg {
              pointer-events: visible;
            }

            &.shadow-left {
              left: 0;
              background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.shadow-right {
              right: 0;
              background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0),
                rgba(241, 241, 241, 1)
              );
            }

            &.swiper-button-disabled {
              opacity: 0;
              z-index: 0;
            }
            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;
              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }
          }

          .swiper-container {
            @media only screen and (max-width: 1024px) {
              padding: 20px !important;
              margin: -20px !important;
            }
          }
        }
      }
    }
  }
}
</style>
